import React from "react";
import SimpleLanding from "../../../layouts/simple-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";

const eDisclosures = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Electronic Signatures Delivery Disclosures"
    }
  ];

  const SEOData = {
    title: "Electronic Signatures Delivery",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container text-center">
        <h1>Borrower Electronic Disclosures Authorization</h1>
        <p>Consent and Agreement to Conduct Transactions Electronically (Agreement)</p>
      </section>
      <section className="container pt-0">
        <p>
          <strong>Electronic Signature and Adoption</strong>
        </p>
        <p>
          By accepting this Agreement, you consent and agree that your use of a key pad, mouse or other device to select
          an item, button, icon or similar act or action while using any electronic service we offer or in accessing or
          making any transactions regarding any Documents constitutes your signature as if actually signed by you in
          writing (electronic signature). In some cases your electronic signature may be represented by your type
          written name or initials electronically placed on the Documents by means of the process described herein. Your
          electronic signature, whether expressed by process or symbolically or both, is hereby adopted by you and
          represents your intent to acknowledge receipt or sign, as applicable, the Documents and that your electronic
          signatures are thereby attached to, and logically associated with, the Documents. Further, you agree that no
          certification authority or other third party verification is necessary to the validity of your electronic
          signature and that the lack of such certification or third party verification will not in any way affect the
          enforceability of your signature or any resulting contract between you and us.
        </p>
        <p>
          <strong>Electronic Delivery</strong>
        </p>
        <p>
          We may provide the Documents to you electronically by e-mail (or a link set forth in an e-mail), online
          posting at an online location designated by us, or Portable Document Format (&ldquo;PDF&rdquo;) files such as
          Adobe Acrobat Reader. The Documents may be provided by us to you directly or by a third party service
          provider.
        </p>
        <p>
          <strong>Consent Coverage; Notices From You Are Not Covered</strong>
        </p>
        <p>
          Applicable law or contracts sometimes require you to give us &ldquo;written&rdquo; notices, and this Agreement
          does not relate to those items. In order to coordinate our processing, you must still provide us with written
          notices on paper.
        </p>
        <p>
          <strong>Electronic or Paper Communications</strong>
        </p>
        <p>
          Receiving the Documents electronically is an option. You may choose not to receive Documents electronically by
          clicking the &ldquo;I decline&rdquo; button at the end of this Agreement. This is not the same as withdewing
          your consent to receive Documents electronically, which is addressed in the section of this Agreement entitled
          &ldquo;Withdrawing Consent&rdquo;. If you click &ldquo;I decline&rdquo; below, you have the right to change
          your mind and receive the Documents electronically for as long as the emailed invitation link we sent you to
          this signing room is active unless you withdraw any previously provided consent. If you choose not to receive
          the Documents electronically or do not complete the entire consent and signing process, the Documents for the
          loan you have applied for will be provided in paper form.
        </p>
        <p>
          <strong>Withdrawing Consent</strong>
        </p>
        <p>
          You have the right to withdraw your consent and agreement to receive electronic Documents at any time. Because
          we will provide some Documents to you electronically almost instantaneously once you have given us your
          consent to do business with us electronically, you will not be able to withdraw your consent relating to the
          Documents we have already provided to you pursuant to that consent. However, you may withdraw your consent to
          receive subsequent Documents electronically, in which case we will deliver all subsequent Documents to you in
          paper form via mail at no cost to you. You may withdraw your consent by contacting us at WaFd Bank,{" "}
          <a href="tel: 800-324-9375">800-324-9375</a>. If you decide to withdraw your consent, the legal validity and
          enforceability of our prior electronic Documents and communications to you will not be affected.
        </p>
        <p>
          <strong>Hardware and Software Requirements</strong>
        </p>
        <p>
          In order to receive access and retain the Documents from us electronically, you will need the following
          computer software and hardware:
        </p>
        <ol>
          <li>
            A computer is connected with an Internet Service Provider and an internet email account and email address.
            The definition of a computer excludes tablets and mobile devices.
          </li>
          <li>A monitor with the ability to view the Documents.</li>
          <li>Microsoft Internet Explorer version 6.0 or higher internet browser with 128 bit encryption or higher</li>
          <li>
            AdobeA Reader 5.0 or higher. If you do not have Adobe Reader 5.0 or higher, Adobe Reader software is
            available for FREE in various languages on the Adobe website (
            <a taget="_blank" rel="noopener noreferrer" href="https://www.adobe.com/">
              www.adobe.com
            </a>
            ).
          </li>
          <li>
            Access to a printer that is capable of printing information displayed on your monitor or the ability to save
            or download files on a storage device for later reference, in order to keep copies of your Documents for
            your records. You will need at least 5 MB of storage space available.
          </li>
        </ol>
        <p>
          If you do not have the required software and/or hardware, or if you do not wish to receive the Documents
          electronically from us for any other reason, do not click &ldquo;Submit.&rdquo; If you click
          &ldquo;Submit,&rdquo; this electronic signature session will be closed.
        </p>
        <p>
          If the software or hardware requirements change in the future, and you are unable to continue receiving
          Documents electronically, we will mail you paper copies of Documents once you notify us that you are no longer
          able to access the Documents electronically because of the changed requirements. We will use commercially
          reasonable efforts to notify you before such requirements change. If you chose to withdraw your consent upon
          notification of the changes, you will be able to do so without penalty.
        </p>
        <p>
          Documents are not available from us electronically to you indefinitely. You are responsible for printing
          and/or saving a copy of all Documents provided to you electronically. If you require copies of your Documents
          and they are no longer available from us electronically, we will provide you copies in paper form at no cost
          to you upon your request to us at WaFd Bank. If the Documents contain any confidential or sensitive
          information such as your application information or account information, be sure to store them in a secure
          environment, just as you would paper-based bank records.
        </p>
        <p>
          <strong>Changes to Your Contact Information</strong>
        </p>
        <p>
          You have provided us with your email address. You must keep your email address current with us at all times.
          You must promptly notify us at WaFd Bank if there is a change in your email address or mailing address, or in
          other information we need to contact you.
        </p>
        <p>
          Unless otherwise required by law, you agree that any Documents we deliver electronically will be deemed
          received by you electronically signed and made available to you to save or print or when sent to the most
          current email address you provided us. We will not assume liability for non-receipt of notification or the
          availability of electronic Documents in the event your email address on file is invalid, your email or
          Internet service provider filters the notification as &ldquo;spam&rdquo; or &ldquo;junk mail&rdquo;, there is
          a malfunction in your computer, browser, Internet service and/or software or for any other reasons beyond our
          control.
        </p>
        <p>
          <strong>YOUR RIGHT TO RECEIVE A COPY OF AN APPRAISAL</strong>
        </p>
        <p>
          We may order an appraisal to determine the property's value and charge you for this appraisal. We will
          promptly give you a copy of any appraisal, even if your loan does not close. You can pay for an additional
          appraisal for your own use at your own cost.
        </p>
        <p>
          <strong>YOUR ABILITY TO ACCESS DOCUMENTS</strong>
        </p>
        <p>
          By clicking &ldquo;Submit&rdquo; button, you acknowledge that you can access the Documents in the designated
          formats described above, and that the computer(s) you are using now, and will later use, meet the system
          requirements described above. You also acknowledge that: (a) you have been able to read this Agreement using
          your computer and software; (b) you have successfully printed and/or downloaded a copy of this agreement; (c)
          you have access to an account with an internet service provider; and (d) you are able to send and receive
          e-mail.
        </p>
        <p>
          <strong>CONSENT AND AGREEMENT</strong>
        </p>
        <p>
          By clicking the &ldquo;Submit&rdquo; button, you consent to having all Documents provided or made available to
          you in electronic form, to the terms of this Agreement and to doing business with us electronically. You also
          consent and agree to the use of electronic records and electronic signatures in connection with transactions
          us and specifically with regard to your residential mortgage loan transaction instead of written documents and
          handwritten signatures.
        </p>
        <p>
          BY CLICKING &ldquo;SUBMIT&rdquo; you agree to the terms of this Agreement and choose to conduct this
          transaction electronically, receive the Documents electronically and be legally bound by use of your
          electronic signature in connection with your loan transaction.
        </p>
      </section>
    </SimpleLanding>
  );
};

export default eDisclosures;
